import { render, staticRenderFns } from "./Encurtador.vue?vue&type=template&id=34d64096&scoped=true&"
import script from "./Encurtador.vue?vue&type=script&lang=js&"
export * from "./Encurtador.vue?vue&type=script&lang=js&"
import style0 from "./Encurtador.vue?vue&type=style&index=0&id=34d64096&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d64096",
  null
  
)

export default component.exports