<template>
  <div class="minha-conta" style="height:100vh">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div class="bg-gradient">
        <div class="text-center my-5">
          <h3>Encurtador</h3>
          <div class="h-line" style="width:142px;"></div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-8 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Digite o link
              </span>
            </div>
            <input type="text" class="form-control" v-model="link" />
          </div>
        </div>

        <div class="col-md-4 mb-2">
          <button
            type="submit"
            class="btn btn-success btn-block mb-3"
            @click.prevent="encurtarlink(link)"
            :disabled="loading"
          >
            <span v-if="!loading">Encurtar</span>
            <span v-if="loading">Por favor aguarde...</span>
          </button>
        </div>
      </div>
      <div class="row" v-if="encurtado">
        <b-card class="mb-2">
          <h5>👇 Aqui está seu link encurtado!</h5>
          <div>
            <b-form-input
              :value="encurtado"
              id="myInput"
              type="text"
              readonly
              style="font-size:14px;"
            ></b-form-input>
          </div>
          <div>
            <b-button
              class="mt-4 mb-2"
              block
              @click.prevent="copiarLink()"
              variant="primary"
              >Copiar link</b-button
            >
          </div>
        </b-card>
      </div>
      <!-- Nossa Table -->
    </div>
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    SideNav,
  },
  data() {
    return {
      link: "",
      encurtado: "",
      loading: false,
    };
  },
  methods: {
    copiarLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O encurtado foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
    async encurtarlink(value) {
      this.loading = true;
      if (!value || !value.startsWith("https://")) {
        this.loading = false;
        return;
      } else {
        try {
          const data = {
            url: this.link,
          };
          const response = await axios.post(
            `https://api-bikeregistrada-v5.azurewebsites.net/bike/`,
            data
          );
          this.encurtado = response.data;
          this.loading = false;
        } catch {
          this.loading = false;
          Swal.fire({
            title: "Não foi possível encurtar seu link",
            text: "Tente novamente mais tarde ou contate o suporte TI.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>
